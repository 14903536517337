import '../scss/carousel-podium.scss'

// Initialization
window.addEventListener('DOMContentLoaded', () => {
    if (window.carouselPodiumInit) {
        return;
    }

    const podiumCarousels = document.querySelectorAll('.carousel-podium'); // Adjust selector if needed
    podiumCarousels.forEach(initializePodiumCarousel);

    window.carouselPodiumInit = true;
});

/**
 * Initializes an individual podium carousel instance.
 * @param {HTMLElement} carouselPodium - The root element of the carousel.
 */
function initializePodiumCarousel(carouselPodium) {
    const carouselList = carouselPodium.querySelector('.carousel-podium__list');
    const carouselPrev = carouselPodium.querySelector('.carousel-podium__prev');
    const carouselNext = carouselPodium.querySelector('.carousel-podium__next');
    const carouselItems = carouselPodium.querySelectorAll('.carousel-podium__item');
    const elements = Array.from(carouselItems);

    carouselPrev.addEventListener('click', (event) => {
        const previous = elements.find((elem) => elem.dataset.position == -1);

        updatePosition(carouselPodium, elements, previous);
    });

    carouselNext.addEventListener('click', (event) => {
        const next = elements.find((elem) => elem.dataset.position == 1);

        updatePosition(carouselPodium, elements, next);
    });

    carouselList.addEventListener('click', (event) => {
        let newActive = event.target.closest('.carousel-podium__item');
        let isItem = newActive.closest('.carousel-podium__item');

        if (!isItem || newActive.classList.contains('carousel-podium__item-active')) {
            return;
        }

        updatePosition(carouselPodium, elements, newActive);
    });

    const initialCTA = carouselPodium.querySelector('.carousel-podium__caption-cta[data-index="0"]');

    if (initialCTA) {
        initialCTA.style.display = 'block';
    }
}

/**
 * Updates the new active position.
 * @param {HTMLElement} carouselPodium - Instance of the carousel podium.
 * @param {Array} elements - Carousel Elements
 * @param {HTMLElement} newActiveItem
 */
const updatePosition = (carouselPodium, elements, newActiveItem) => {
    const newActivePosition = Number(newActiveItem.dataset.position);

    elements.forEach(item => {
        let itemPosition = item.dataset.position;

        item.dataset.position = getPosition(itemPosition, newActivePosition, elements.length)
    });

    const activeCTA = carouselPodium.querySelector(`.carousel-podium__caption-cta[data-index="${newActiveItem.dataset.index}"]`);
    const carouselCTAs = carouselPodium.querySelectorAll('.carousel-podium__caption-cta');
    carouselCTAs.forEach(cta => cta.style.display = 'none');

    if (activeCTA) {
        activeCTA.style.display = 'block';
    }
};

/**
 * Calculates the new active position.
 * @param {number} current - Current Position
 * @param {number} active - Active Item Position
 * @param {number} elementsCount - Number of carousel items
 * @returns {number} - Returns Position
 */
const getPosition = (current, active, elementsCount) => {
    const diff = current - active;

    if (Math.abs(current - active) > Math.floor(elementsCount / 2)) {
        return - current
    }

    return diff;
}